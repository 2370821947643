import React from "react";
import Layout from "../components/Layout";
import {Link} from "gatsby-plugin-modal-routing";
import data from "../../content/resources/sector_salud.json";


export default function Salud() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(/../images/sectores/1.jpg)`}}
                    >
                        <h1>Salud</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Somos una empresa 100% mexicana con 32 años ha comercializado bases de datos e información
                            científica para ciencias de la salud, consolidando su presencia en los sectores hospitalario
                            y farmacéutico Disponemos de fuentes de información confiables publicadas por editores de
                            amplia visibilidad internacional, que apoyan la innovación con sustento científico de las
                            siguientes áreas:
                        </p>
                        <ul style={{color: '#000000'}}>
                            <li>Dirección Médica</li>
                            <li>Farmacovigilancia</li>
                            <li>Investigación y desarrollo</li>
                            <li>Mercadotecnia</li>
                            <li>Prescripción y práctica clínica</li>
                        </ul>
                    </div>
                    <section className="tiles">
                        {data.map((service) => {
                            return (
                                <article key={service.id} className="style1">
                                    <span className="image">
                                        <img
                                            src={service.image}
                                            alt={service.alt}/>
                                    </span>
                                    <Link to={service.path} state={{service: service}}>
                                        <h2>{service.name}</h2>
                                        <div className="content">
                                            <p>
                                                {service.description}
                                            </p>
                                        </div>
                                    </Link>
                                </article>
                            )
                        })}
                    </section>
                </div>
            </div>
        </Layout>
    )
}